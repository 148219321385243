'use strict';

angular.module('enervexSalesappApp').controller('AccountTerritoriesCtrl', function($scope, AccountTerritory, $stateParams) {
	$scope.accountId = $stateParams.accountId;
	AccountTerritory.query({
		accountId: $stateParams.accountId,
		accountLocationId: $stateParams.accountLocationId
	}).$promise.then(function(accountTerritories){
		$scope.accountTerritories = accountTerritories;
	});
});
